import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex lg:px-[60px] py-[18px] border-b-[1px] justify-between" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex items-center gap-[6px] w-fit px-[20px] py-[3px]" }
const _hoisted_5 = {
  class: "grid items-center gap-[10px]",
  style: {"grid-template-columns":"1fr auto auto"}
}
const _hoisted_6 = { class: "lg:block hidden" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "lg:block hidden relative" }
const _hoisted_9 = {
  key: 0,
  class: "absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50"
}
const _hoisted_10 = { class: "flex justify-between gap-[8px] px-[16px] mb-[10px]" }
const _hoisted_11 = { class: "px-[16px] mt-[20px]" }
const _hoisted_12 = { class: "lg:hidden" }
const _hoisted_13 = { class: "mt-[80px]" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "flex gap-[8px]" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "font-semibold" }
const _hoisted_19 = { class: "text-caption text-grey-200" }
const _hoisted_20 = { class: "flex items-center justify-between" }
const _hoisted_21 = { class: "text-body-2 font-medium" }
const _hoisted_22 = { class: "bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "bg-grey-50" }
const _hoisted_25 = { class: "px-[24px] py-[16px] border-b-[1px] border-solid border-grey-50 flex items-center justify-between text-grey-200" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "flex gap-[8px]" }
const _hoisted_28 = { class: "font-semibold" }
const _hoisted_29 = { class: "grid items-center gap-[8px]" }
const _hoisted_30 = { class: "text-body-2 font-medium" }
const _hoisted_31 = { class: "bg-white rounded-full w-[32px] h-[32px] relative" }
const _hoisted_32 = { class: "border-t-[1px] border-solid border-grey-50 px-[24px] py-[24px]" }
const _hoisted_33 = { class: "lg:flex gap-[16px] lg:w-[1000px] m-auto pt-[63px] pb-[50px] relative" }
const _hoisted_34 = { class: "" }
const _hoisted_35 = { class: "relative text-white" }
const _hoisted_36 = { class: "text-caption flex gap-[8px] items-center w-fit mx-auto lg:mx-0" }
const _hoisted_37 = { class: "lg:flex items-center text-caption gap-[8px] w-fit mx-auto lg:mx-0" }
const _hoisted_38 = { class: "px-[13px] pb-[24px] relative lg:block hidden" }
const _hoisted_39 = { class: "pt-[32px] w-[100%]" }
const _hoisted_40 = { class: "bg-[#f0f0f0]" }
const _hoisted_41 = { class: "grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] py-[32px]" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "font-semibold mt-[12px]" }
const _hoisted_44 = { class: "my-[12px] text-caption text-grey-200" }
const _hoisted_45 = { class: "modal-overlay" }
const _hoisted_46 = { class: "modal-conten w-[550px] h-full overflow-auto relative no-scrollbar" }
const _hoisted_47 = { class: "bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_48 = { class: "grid gap-[8px] mt-[16px]" }
const _hoisted_49 = { class: "flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]" }
const _hoisted_50 = { class: "text-caption text-grey-200 underline" }
const _hoisted_51 = { class: "modal-overlay" }
const _hoisted_52 = { class: "modal-conten w-[550px] h-full overflow-auto relative no-scrollbar" }
const _hoisted_53 = { class: "bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_54 = { class: "grid gap-[8px] my-[16px]" }
const _hoisted_55 = { class: "grid grid-cols-2 items-center gap-[16px]" }
const _hoisted_56 = { class: "flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]" }
const _hoisted_57 = { class: "text-caption text-grey-200 underline" }
const _hoisted_58 = { class: "relative" }
const _hoisted_59 = { class: "flex items-center justify-between lg:px-[32px] px-[10px] pt-[24px]" }
const _hoisted_60 = { class: "font-semibold text-heading-4" }
const _hoisted_61 = { class: "text-body-2 font-medium text-grey-300" }
const _hoisted_62 = { class: "flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]" }
const _hoisted_63 = { class: "bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]" }
const _hoisted_64 = { class: "" }
const _hoisted_65 = { class: "flex items-center gap-[16px] border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] py-[24px] mt-[24px]" }
const _hoisted_66 = { class: "mt-[100px] flex items-center mx-[20px] text-body-2 gap-[32px] border-b-[1px] border-grey-50 text-grey-200" }
const _hoisted_67 = { class: "flex gap-[8px] p-[10px]" }
const _hoisted_68 = { class: "" }
const _hoisted_69 = { class: "font-semibold" }
const _hoisted_70 = { class: "text-caption text-grey-200" }
const _hoisted_71 = { class: "bg-grey-50 py-[4px] px-[8px] flex items-center justify-between text-caption rounded-b-[8px]" }
const _hoisted_72 = {
  key: 0,
  class: "text-grey-200"
}

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import SideModel from '@/components/shared/modal/SideModel.vue';
import StarRating from '@/components/shared/ratings/StarRating.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { Icon } from '@iconify/vue';
import { ref, onMounted, onUnmounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RestaurantView',
  setup(__props) {

const isModalOpen = ref(false);
const selectedMenuDetails = ref();

const isSideModalOpen = ref(false);

const openSideModal = () => {
    isSideModalOpen.value = true; 
};

const closeSideModal = () => {
    isSideModalOpen.value = false; 
};

const openModal = (menus: any) => {
    selectedMenuDetails.value = menus; 
    isModalOpen.value = true; 
    isDropdownOpen.value = false;
};

const imageStyle = ref({
    // height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.stirworld.com%2Fimages%2Farticle_gallery%2Fzaytinya-is-a-new-eatery-by-two-star-michelin-chef-jos-eacute-andr-eacute-s-zaytinya-rockwell-group-stirworld-220929043534.jpg&f=1&nofb=1&ipt=201842be87996a6b5946c2b16a387039b495525223f509fb5d9f4463f75c3799&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // filter: 'blur(1px)'
});

const getBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const getCartBackgroundStyle = (imageUrl: string, height?: number, width?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: `${width}px` || '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});


const menus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fnomadparadise.com%2Fwp-content%2Fuploads%2F2020%2F04%2Fsa-food-005-1024x640.jpg&f=1&nofb=1&ipt=c95b0e6b885aa6b034de6d5b73586cd1adf5499db1fb684380c6ed4e93a2e3e1&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fb5%2F3e%2F48%2Fb53e481091c747f79808459a3db764c3.jpg&f=1&nofb=1&ipt=f5257e5207d9140cb2487f73e7c3befd32a16c75196f1f94bc025712cb2bb368&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fsisijemimah.com%2Fwp-content%2Fuploads%2F2015%2F12%2FNigerian-Assorted-peppered-meats-10.jpg&f=1&nofb=1&ipt=ca2e170b97e09d76a97d12c7cab7958ce2c6abeca07498cd43570fd2b5fd73f8&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F83%2F8e%2F31%2F838e31fb16313577a72f4dde938f0702.jpg&f=1&nofb=1&ipt=27d5a5c9b671c641dc526337d04ef743dafb841c4dc8a5ba9acd945ab9e29629&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fbd%2F06%2Fe3%2Fbd06e3b4de358b7c5700150e987b2850.jpg&f=1&nofb=1&ipt=a30e9a774659d0d751a48548fe235f32f46ce8c2fbb0cf1d914b79cfeeb153b2&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },
]);

const cartMenus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },
]);

const isFixed = ref(false);
const navBar = ref<HTMLElement | null>(null);
let navBarOffsetTop = 0;

const handleScroll = () => {
    if (navBar.value) {
        const currentScroll = window.scrollY;
        isFixed.value = currentScroll >= navBarOffsetTop;
    }
};

onMounted(() => {
    if (navBar.value) {
        navBarOffsetTop = navBar.value.offsetTop; 
    }
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

const quantity = ref(1);

const incrementQuantity = () => {
    quantity.value += 1;
};

const decrementQuantity = () => {
    if (quantity.value > 1) { 
        quantity.value -= 1;
    }
};

const isListVisible = ref(true);

const toggleListVisibility = () => {
    isListVisible.value = !isListVisible.value;
};

const isDropdownOpen = ref(false);

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

const isLoginModalOpen = ref(false);
const isSignUpModalOpen = ref(false);

const openLoginModal = () => {
    isLoginModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const openSignUpModal = () => {
    isSignUpModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedMenuDetails.value = null; 
};


const isOrdersModalOpen = ref(false);
const currentTab = ref('Ongoing'); 

const orderStatuses = {
    Ongoing: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
    ],
    Delivered: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Delivered',
            deliveryDate: 'Delivered on: 04 July, 2024',
            receipt: 'Download receipt'
        }
    ],
    Cancelled: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled - Payment Unsuccessful',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        }
    ]
};

const changeTab = (tab: string) => {
    currentTab.value = tab;
};

const openOrdersModal = () => {
    isOrdersModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeOrdersModal = () => {
    isOrdersModalOpen.value = false;
};

const dropdownItems = [
    { icon: 'ant-design:shopping-outlined', text: 'Orders', action: openOrdersModal },
    { icon: 'ant-design:bell-outlined', text: 'Notifications' },
    { icon: 'ant-design:history-outlined', text: 'Recently Viewed' },
    { icon: 'ant-design:gift-outlined', text: 'Rewards' },
    { icon: 'ant-design:heart-outlined', text: 'Favorite Items' },
    { icon: 'ant-design:logout-outlined', text: 'Logout', class: 'border-t-[1px] border-grey-50 text-error pt-[11px] -mb-[11px]' }
];

const getStatusColorClass = (status: any) => {
    switch (status) {
        case 'Shipped':
            return 'bg-yellow-500'; 
        case 'Waiting to be shipped':
            return 'bg-link text-white'; 
        case 'Delivered':
            return 'bg-success text-white'; 
        case 'Cancelled - Payment Unsuccessful':
            return 'bg-black text-white'; 
        case 'Cancelled':
            return 'bg-black text-white'; 
        default:
            return '';
    }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "48",
                height: "48"
              }, null, 8, ["imageUrl"]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(InputField, {
            type: "text",
            prefixIcon: "ant-design:search-outlined"
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(ActionButton, {
            theme: "primary",
            prefixIcon: "ant-design:shopping-filled",
            label: "0",
            class: "w-fit whitespace-nowrap",
            handleClick: openSideModal
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(ActionButton, {
            theme: "outlined",
            prefixIcon: "ant-design:user-outlined",
            label: "Account",
            class: "w-fit whitespace-nowrap",
            onClick: toggleDropdown
          }),
          (isDropdownOpen.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(ActionButton, {
                    theme: "outlined",
                    label: "Login",
                    class: "whitespace-nowrap",
                    onClick: openLoginModal
                  }),
                  _createVNode(ActionButton, {
                    theme: "primary",
                    label: "Sign Up",
                    class: "whitespace-nowrap",
                    onClick: openSignUpModal
                  })
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "border-b-[1px] border-grey-50 relative" }, [
                  _createElementVNode("div", { class: "mx-auto mb-[-12px] bg-white px-[8px] w-fit" }, "or")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(ActionButton, {
                    theme: "outlined",
                    prefixIcon: "ant-design:google-outlined",
                    label: "Continue with Google",
                    class: "w-fit whitespace-nowrap"
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(ActionButton, {
            theme: "outlined",
            prefixIcon: "ant-design:menu-outlined",
            label: "",
            class: "w-fit whitespace-nowrap"
          })
        ])
      ]),
      _createVNode(SideModel, {
        openModal: isSideModalOpen.value,
        onClose: closeSideModal,
        "header-content": "Shopping Cart"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _cache[6] || (_cache[6] = _createElementVNode("small", { class: "p-[24px]" }, "Order Summary", -1)),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartMenus.value, (menu, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "rounded-[8px] cursor-pointer grid gap-[8px] border-b-[1px] border-solid border-grey-50 p-[24px]",
                  onClick: ($event: any) => (openModal(menu))
                }, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(getCartBackgroundStyle(menu.imageUrl, 60, 60))
                    }, null, 4),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(menu.name), 1),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(menu.ingredient), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(menu.price), 1),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", {
                        class: "bg-white rounded-full w-[32px] h-[32px] relative",
                        onClick: decrementQuantity
                      }, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:minus-outlined",
                          width: "18",
                          height: "18",
                          class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_23, _toDisplayString(quantity.value), 1),
                      _createElementVNode("div", {
                        class: "bg-white rounded-full w-[32px] h-[32px] relative",
                        onClick: incrementQuantity
                      }, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:plus-outlined",
                          width: "18",
                          height: "18",
                          class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                        })
                      ])
                    ])
                  ])
                ], 8, _hoisted_15))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-body-2" }, "Did you get everything", -1)),
                _createVNode(_unref(Icon), {
                  icon: "ant-design:caret-down-filled",
                  width: "18",
                  height: "18",
                  onClick: toggleListVisibility
                })
              ]),
              (isListVisible.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartMenus.value, (menu, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "cursor-pointer flex justify-between border-b-[1px] border-solid border-grey-50 p-[24px]"
                      }, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", {
                            style: _normalizeStyle(getCartBackgroundStyle(menu.imageUrl, 60, 60))
                          }, null, 4),
                          _createElementVNode("div", _hoisted_28, _toDisplayString(menu.name), 1)
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, _toDisplayString(menu.price), 1),
                          _createElementVNode("div", _hoisted_31, [
                            _createVNode(_unref(Icon), {
                              icon: "ant-design:plus-outlined",
                              width: "18",
                              height: "18",
                              class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                            })
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex items-center justify-between p-[24px]" }, [
              _createElementVNode("div", { class: "text-caption text-grey-100" }, "Subtotal (excluding taxes):"),
              _createElementVNode("div", { class: "font-bold" }, "N15,400.00")
            ], -1)),
            _createElementVNode("div", _hoisted_32, [
              _createVNode(_component_router_link, { to: "/checkout" }, {
                default: _withCtx(() => [
                  _createVNode(ActionButton, {
                    theme: "primary",
                    label: "Checkout",
                    class: "whitespace-nowrap"
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["openModal"])
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-70" }, null, -1)),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/images/bo-cafe.jpeg'),
            width: "100",
            height: "100",
            class: "w-fit mx-auto rounded-full border-[2px] border-white overflow-hidden"
          }, null, 8, ["imageUrl"])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-heading-4 font-bold lg:text-left text-center" }, "Bo Cafe", -1)),
          _createElementVNode("div", _hoisted_36, [
            _createVNode(StarRating, { maxStars: 5 }),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "" }, "3.0 (505 price)", -1))
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mb-4 text-body-2 lg:text-left text-center lg:px-0 px-[16px]" }, " Hailing from a Punjabi family in India, Chef Arveen's upbringing immersed her in the rich tapestry of Indian flavours and traditions. Inspired by her mother and mother ...see more ", -1)),
          _createElementVNode("div", _hoisted_37, [
            _createVNode(ActionButton, {
              label: "Opened",
              class: "w-fit whitespace-nowrap bg-success text-white text-caption w-fit mx-auto lg:mx-0 mb-2 lg:mb-0"
            }),
            _cache[9] || (_cache[9] = _createElementVNode("div", null, "Opening time: 7:00 AM to 11:00 PM", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_38, [
        _createVNode(ActionButton, {
          theme: "outlined",
          label: "Contact Us",
          class: "w-fit whitespace-nowrap ml-auto relative text-white border-white"
        })
      ])
    ], 4),
    _createElementVNode("main", _hoisted_39, [
      _createElementVNode("div", {
        ref_key: "navBar",
        ref: navBar,
        class: _normalizeClass([{ 'fixed-top': isFixed.value }, "whitespace-nowrap overflow-auto no-scrollbar"])
      }, _cache[13] || (_cache[13] = [
        _createStaticVNode("<div class=\"flex lg:gap-[24px] gap-[10px] border-b-[1px] lg:px-[60px] font-medium text-grey-100 text-body-2\" data-v-489ba4ac><div class=\"p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer\" data-v-489ba4ac>African Soup</div><div class=\"p-[16px] cursor-not-allowed\" data-v-489ba4ac>Break-fast</div><div class=\"p-[16px] cursor-not-allowed\" data-v-489ba4ac>Cupcake</div><div class=\"p-[16px] cursor-not-allowed\" data-v-489ba4ac>Pepper soup</div><div class=\"p-[16px] cursor-not-allowed\" data-v-489ba4ac>Drinks</div></div>", 1)
      ]), 2),
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("div", _hoisted_41, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menus.value, (menu, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "lg:p-[12px] p-[6px] rounded-[8px] cursor-pointer",
              onClick: ($event: any) => (openModal(menu))
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle(getBackgroundStyle(menu.imageUrl, 200))
              }, null, 4),
              _createElementVNode("div", _hoisted_43, _toDisplayString(menu.name), 1),
              _createElementVNode("div", _hoisted_44, _toDisplayString(menu.price), 1)
            ], 8, _hoisted_42))
          }), 128))
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-grey-300 text-sub-heading" }, "Login", -1)),
            _createElementVNode("div", _hoisted_48, [
              _createVNode(InputField, {
                type: "email",
                label: "email"
              }),
              _createVNode(InputField, {
                type: "password",
                label: "Password"
              })
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-right text-caption text-grey-200 underline mb-[16px] mt-[8px]" }, "Forget Password", -1)),
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Login",
              class: "whitespace-nowrap mb-[16px]"
            }),
            _createElementVNode("div", _hoisted_49, [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-caption text-grey-200 underline" }, "Don’t have an account?", -1)),
              _createElementVNode("div", _hoisted_50, [
                _createVNode(ActionButton, {
                  theme: "outlined",
                  label: "Signup",
                  class: "whitespace-nowrap"
                })
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, isLoginModalOpen.value]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, [
          _createElementVNode("div", _hoisted_53, [
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-grey-300 text-sub-heading" }, "Sign Up", -1)),
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("div", _hoisted_55, [
                _createVNode(InputField, {
                  type: "text",
                  label: "First Name"
                }),
                _createVNode(InputField, {
                  type: "text",
                  label: "Last Name"
                })
              ]),
              _createVNode(InputField, {
                type: "email",
                label: "Email"
              }),
              _createVNode(InputField, {
                type: "password",
                label: "Password"
              })
            ]),
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Sign Up",
              class: "whitespace-nowrap mb-[16px]"
            }),
            _createElementVNode("div", _hoisted_56, [
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-caption text-grey-200 underline" }, "Already have an account?", -1)),
              _createElementVNode("div", _hoisted_57, [
                _createVNode(ActionButton, {
                  theme: "outlined",
                  label: "Login",
                  class: "whitespace-nowrap"
                })
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, isSignUpModalOpen.value]
      ]),
      _createVNode(AppModal, {
        openModal: isModalOpen.value,
        onClose: closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_58, [
            _createElementVNode("div", {
              style: _normalizeStyle(getBackgroundStyle(selectedMenuDetails.value?.imageUrl, 300))
            }, null, 4),
            _createElementVNode("div", {
              onClick: closeModal,
              class: "absolute right-[20px] top-[20px] bg-black opacity-70 w-[32px] h-[32px] rounded-[8px] border-[1px] border-white cursor-pointer"
            }, [
              _createVNode(_unref(Icon), {
                icon: "ant-design:close-outlined",
                width: "18",
                height: "18",
                class: "text-white absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_59, [
            _createElementVNode("div", _hoisted_60, _toDisplayString(selectedMenuDetails.value?.name), 1),
            _createElementVNode("div", _hoisted_61, _toDisplayString(selectedMenuDetails.value?.price), 1)
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "text-grey-200 font-medium border-b-[1px] border-solid border-grey-50 py-[24px] lg:px-[32px] px-[10px]" }, " Tender pieces of chicken simmered in a rich, house-made, tomatoes and cashew nut based gravy. Perfectly paired with Sooper's Roti, Kulcha, or Parantha flatbreads. ", -1)),
          _createElementVNode("div", _hoisted_62, [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "font-semibold text-body-2" }, "Quantity: ", -1)),
            _createElementVNode("div", _hoisted_63, [
              _createElementVNode("div", {
                class: "bg-white rounded-full w-[32px] h-[32px] relative",
                onClick: decrementQuantity
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:minus-outlined",
                  width: "18",
                  height: "18",
                  class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                })
              ]),
              _createElementVNode("div", _hoisted_64, _toDisplayString(quantity.value), 1),
              _createElementVNode("div", {
                class: "bg-white rounded-full w-[32px] h-[32px] relative",
                onClick: incrementQuantity
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:plus-outlined",
                  width: "18",
                  height: "18",
                  class: "text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                })
              ])
            ])
          ]),
          _createVNode(TextArea, {
            label: "Add a note",
            placeholder: "Add a note to your order (optional)",
            class: "lg:px-[32px] px-[10px]"
          }),
          _createElementVNode("div", _hoisted_65, [
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Add to Cart (N1,200)",
              class: "whitespace-nowrap"
            }),
            _createVNode(ActionButton, {
              label: "Buy Now (N1,200)",
              class: "whitespace-nowrap bg-grey-50 text-grey-300"
            })
          ])
        ]),
        _: 1
      }, 8, ["openModal"]),
      _createVNode(AppModal, {
        "header-content": "Orders",
        openModal: isOrdersModalOpen.value,
        onClose: closeOrdersModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_66, [
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Ongoing'}, "py-[12px] cursor-pointer"]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (changeTab('Ongoing')))
            }, "Ongoing", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Delivered'}, "cursor-pointer"]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (changeTab('Delivered')))
            }, "Delivered", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'text-primary-300 border-b-[2px] border-primary-300': currentTab.value === 'Cancelled'}, "cursor-pointer"]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (changeTab('Cancelled')))
            }, "Cancelled/Returned", 2)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderStatuses[currentTab.value as keyof typeof orderStatuses], (order, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "rounded-[8px] cursor-pointer grid gap-[8px] border-[1px] border-solid border-grey-50 m-[20px]"
            }, [
              _createElementVNode("div", _hoisted_67, [
                _createElementVNode("div", {
                  style: _normalizeStyle(getCartBackgroundStyle(order.imageUrl, 84, 84))
                }, null, 4),
                _createElementVNode("div", _hoisted_68, [
                  _createElementVNode("div", _hoisted_69, _toDisplayString(order.name), 1),
                  _createElementVNode("div", _hoisted_70, _toDisplayString(order.ingredient), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass([getStatusColorClass(order.status), "px-[8px] py-[4px] rounded-[4px] w-fit text-caption mt-[14px]"])
                  }, _toDisplayString(order.status), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_71, [
                _createElementVNode("div", null, _toDisplayString(order.deliveryDate), 1),
                (currentTab.value != 'Cancelled' && 'receipt' in order)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                      _createVNode(_component_router_link, { to: "/receipt" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(order.receipt), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["openModal"])
    ])
  ]))
}
}

})