import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid lg:grid-cols-2" }
const _hoisted_2 = { class: "py-[30px] lg:px-[60px] px-[20px] h-[100vh] overflow-auto no-scrollbar" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex items-center gap-[6px] mb-[60px]" }
const _hoisted_5 = { class: "mt-[24px]" }
const _hoisted_6 = { class: "text-caption text-grey-200" }
const _hoisted_7 = { class: "h-[4px] w-full rounded-[8px] bg-grey-50 mt-[14px]" }
const _hoisted_8 = { class: "mt-[48px]" }
const _hoisted_9 = {
  key: 0,
  class: "grid gap-[16px]"
}
const _hoisted_10 = { class: "grid grid-cols-2 gap-[12px]" }
const _hoisted_11 = {
  key: 1,
  class: "grid gap-[16px]"
}
const _hoisted_12 = { class: "grid grid-cols-2 gap-[12px]" }
const _hoisted_13 = { class: "grid grid-cols-2 gap-[16px] bg-[#EDEDED] p-[20px] rounded-[8px]" }
const _hoisted_14 = { class: "flex gap-[32px]" }

import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { useRegistration } from '@/utility/composables/useAuthSession';
import AuthService from '@/utility/services/auth.service';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RegistrationView',
  setup(__props) {

const router = useRouter();
const { registrationFormHandler } = useRegistration();
const currentStep = ref(1);
const registrationData = ref();

const requiredFieldsStep1 = [
    'name', 'serviceOptions', 'firstName', 'lastName', 'email', 'phone', 'source'
];

function isStep1Complete() {
    return requiredFieldsStep1.every(field => registrationFormHandler.values[field] && registrationFormHandler.values[field] !== '');
}

function isStep2Complete() {
    const addressFields = ['street', 'lga', 'city', 'state', 'postalCode'];
    
    const isAddressComplete = addressFields.every(field => 
        registrationFormHandler.values.address && 
        registrationFormHandler.values.address[field] && 
        registrationFormHandler.values.address[field] !== ''
    );

    const isOtherFieldsComplete = registrationFormHandler.values.numberOfStores &&
                                  registrationFormHandler.values.instagram &&
                                  registrationFormHandler.values.currentPOSSolution &&
                                  registrationFormHandler.values.notes &&
                                  registrationFormHandler.values.hasDevice;

    return isAddressComplete && isOtherFieldsComplete;
}

const handleRegistrationSubmit = async (event: Event) => {
    event.preventDefault();
    
    // if (currentStep.value === 2 && !isStep2Complete()) {
    //     return; 
    // }

    await registrationFormHandler.handleSubmit(event);
    registrationData.value = registrationFormHandler.values;

    router.push('/success');
};

function nextStep() {
    if (currentStep.value === 1 && isStep1Complete()) {
        currentStep.value++;
    }
}

watchEffect(() => {
    console.log(registrationData.value);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "48",
                height: "48"
              }, null, 8, ["imageUrl"]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-itim text-heading-5" }, "Foodcart", -1))
            ])
          ]),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-medium text-heading-4" }, "Registration", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(currentStep.value === 1 ? '1' : '2') + " of 1", 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["h-[4px] bg-primary-300 rounded-[8px]", currentStep.value === 1 ? 'w-[100%]' : 'w-[100%]'])
            }, null, 2)
          ])
        ])
      ]),
      _createElementVNode("main", _hoisted_8, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleRegistrationSubmit($event)), ["prevent"]))
        }, [
          (currentStep.value === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(InputField, {
                  type: "text",
                  label: "Store Name",
                  name: "name",
                  value: _unref(registrationFormHandler).values.name,
                  errors: _unref(registrationFormHandler).errors.name,
                  "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                  "onUpdate:value": _unref(registrationFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(DropdownField, {
                  optionsData: [
                                {name: 'Dine-In', value: 'Dine-In'},
                                {name: 'Take-Out', value: 'Take-Out'},
                                {name: 'Phone-Orders', value: 'Phone-Orders'},
                                {name: 'Reservation', value: 'Reservation'},
                                {name: 'Fine Dinning', value: 'fine-dinning'},
                                {name: 'Casual Dinning', value: 'casual-dinning'},
                                {name: 'Fast Casual', value: 'fast-casual'},
                                {name: 'Bar / Night Club & Restaurant', value: 'bar-night-club-restaurant'},
                                {name: 'Cafe & Bakery', value: 'cafe-bakery'},
                                {name: 'Enterprise / Franchise', value: 'enterprise-franchise'},
                                {name: 'Food Truck', value: 'food-truck'},
                                {name: 'Pizza ', value: 'pizza'},
                                {name: 'Ghost Kitchen', value: 'ghost-kitchen'},
                            ],
                  label: "Store Category",
                  name: "serviceOptions",
                  value: _unref(registrationFormHandler).values.serviceOptions,
                  errors: _unref(registrationFormHandler).errors.serviceOptions,
                  onChange: _unref(registrationFormHandler).handleChange
                }, null, 8, ["value", "errors", "onChange"]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(InputField, {
                    type: "text",
                    label: "First Name",
                    name: "firstName",
                    value: _unref(registrationFormHandler).values.firstName,
                    errors: _unref(registrationFormHandler).errors.firstName,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "Last Name",
                    name: "lastName",
                    value: _unref(registrationFormHandler).values.lastName,
                    errors: _unref(registrationFormHandler).errors.lastName,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createVNode(InputField, {
                  type: "text",
                  label: "Email",
                  name: "email",
                  value: _unref(registrationFormHandler).values.email,
                  errors: _unref(registrationFormHandler).errors.email,
                  "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                  "onUpdate:value": _unref(registrationFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  type: "text",
                  label: "Phone Number",
                  name: "phone",
                  value: _unref(registrationFormHandler).values.phone,
                  errors: _unref(registrationFormHandler).errors.phone,
                  "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                  "onUpdate:value": _unref(registrationFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(DropdownField, {
                  optionsData: [
                                {name: 'Online', value: 'online'},
                                {name: 'Facebook', value: 'facebook'},
                                {name: 'Twitter', value: 'twitter'},
                                {name: 'Instagram', value: 'instagram'},
                                {name: 'Youtube', value: 'youtube'},
                            ],
                  label: "How Did You Hear About Us",
                  name: "source",
                  value: _unref(registrationFormHandler).values.source,
                  errors: _unref(registrationFormHandler).errors.source,
                  onChange: _unref(registrationFormHandler).handleChange,
                  class: "mt-[16px]"
                }, null, 8, ["value", "errors", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (currentStep.value === 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(InputField, {
                    type: "text",
                    label: "Number Of Stores",
                    name: "numberOfStores",
                    value: _unref(registrationFormHandler).values.numberOfStores,
                    errors: _unref(registrationFormHandler).errors.numberOfStores,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "Instagram Username",
                    name: "instagram",
                    value: _unref(registrationFormHandler).values.instagram,
                    errors: _unref(registrationFormHandler).errors.instagram,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createVNode(InputField, {
                  type: "text",
                  label: "Current Solution In Use",
                  name: "currentPOSSolution",
                  value: _unref(registrationFormHandler).values.currentPOSSolution,
                  errors: _unref(registrationFormHandler).errors.currentPOSSolution,
                  "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                  "onUpdate:value": _unref(registrationFormHandler).handleChange
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-body-2 text-grey-200 mt-[7px] inline-block" }, "Address", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(InputField, {
                    type: "text",
                    label: "Street",
                    name: "address.street",
                    value: _unref(registrationFormHandler).values.street,
                    errors: _unref(registrationFormHandler).errors.street,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "Local Government Area",
                    name: "address.lga",
                    value: _unref(registrationFormHandler).values.lga,
                    errors: _unref(registrationFormHandler).errors.lga,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "City",
                    name: "address.city",
                    value: _unref(registrationFormHandler).values.city,
                    errors: _unref(registrationFormHandler).errors.city,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "State",
                    name: "address.state",
                    value: _unref(registrationFormHandler).values.state,
                    errors: _unref(registrationFormHandler).errors.state,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    type: "text",
                    label: "Postal Code",
                    name: "address.postalCode",
                    value: _unref(registrationFormHandler).values.postalCode,
                    errors: _unref(registrationFormHandler).errors.postalCode,
                    "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                    "onUpdate:value": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createVNode(TextArea, {
                  label: "What Problem Would You Like Us To Solve?",
                  name: "notes",
                  value: _unref(registrationFormHandler).values.notes,
                  errors: _unref(registrationFormHandler).errors.notes,
                  "onUpdate:blur:value": _unref(registrationFormHandler).handleBlur,
                  "onUpdate:value": _unref(registrationFormHandler).handleChange,
                  placeholder: "Type here"
                }, null, 8, ["value", "errors", "onUpdate:blur:value", "onUpdate:value"]),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-body-2 text-grey-200 mt-[7px] inline-block" }, "Do you have an existing device?", -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(RadioButton, {
                    value: "true",
                    isChecked: _unref(registrationFormHandler)?.values?.hasDevice === true,
                    name: "hasDevice",
                    "onOn:change": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["isChecked", "onOn:change"]),
                  _createVNode(RadioButton, {
                    value: "false",
                    isChecked: _unref(registrationFormHandler)?.values?.hasDevice === false,
                    name: "hasDevice",
                    "onOn:change": _unref(registrationFormHandler).handleChange
                  }, null, 8, ["isChecked", "onOn:change"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(ActionButton, {
            theme: "primary",
            label: "Submit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (currentStep.value == 1)),
            "is-submit": currentStep.value == 1,
            class: "my-[24px]",
            disable: currentStep.value == 1 ? !isStep1Complete() : !isStep2Complete()
          }, null, 8, ["is-submit", "disable"])
        ], 32),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-caption text-grey-200" }, [
          _createTextVNode(" By clicking \"Submit\", you agree to "),
          _createElementVNode("span", { class: "underline text-grey-300 font-capitalize" }, "FoodCart Terms and Conditions"),
          _createTextVNode(" and acknowledge you have read the "),
          _createElementVNode("span", { class: "underline text-grey-300 font-capitalize" }, " Privacy Notice"),
          _createTextVNode(". ")
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "underline text-grey-300 font-capitalize border-t-[1px] border-grey-50 pt-[24px] mt-[24px] text-center" }, "Already have an account?", -1))
      ])
    ]),
    _cache[8] || (_cache[8] = _createStaticVNode("<aside class=\"lg:block hidden p-[20px]\"><div class=\"relative\" style=\"width:100%;height:95.6vh;border-radius:15px;background-image:url(&#39;https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Fpremium-photo%2Fhappy-woman-cashier-portrait-with-smile-management-small-business-restaurant-positive_590464-254776.jpg&amp;f=1&amp;nofb=1&amp;ipt=1183d4782c574805c04ed8368374678a3d39078c45b3fea1e8bee67266590766&amp;ipo=images&#39;);background-repeat:no-repeat;background-size:cover;background-position:center;\"><div class=\"absolute inset-0 h-[70%] top-[auto]\" style=\"background:linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));border-radius:14px;\"></div><div class=\"text-white w-[640px] absolute bottom-[80px] left-[40px]\"><div class=\"text-heading-3 my-[12px] font-bold\"> FoodCart - Simplify Restaurant <br> Operations </div><div class=\"text-[#FFFFFFCC] font-medium\"> &quot;With FoodCart, managing sales, inventory, and transactions has never been easier. Our platform consolidates all your restaurant data into one place, allowing you to streamline your operations and focus on what matters most – serving your customers.&quot; </div></div></div></aside>", 1))
  ]))
}
}

})