<template>
    <div class="">
        <header class="border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]">
            <router-link to="/restaurant">
                <div class="rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative">
                    <Icon icon="ant-design:arrow-left-outlined" width="18" height="18" class="text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                </div>
            </router-link>
            <h3 class="font-medium text-sub-heading">Back to cart</h3>
        </header>

        <main class="grid lg:grid-cols-2">
            <div class="h-[90vh] bg-grey-50 overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px] no-scrollbar">
                <div class="font-medium">Order Information & Review</div>
                <div class="font-medium text-caption text-grey-200">By placing your order, you agree to foodcart’s <router-link to="/privacy-policy" class="text-primary-300 underline">Privacy</router-link> and <router-link to="/privacy-policy" class="text-primary-300 underline">Policy</router-link></div> 
                <div v-for="(menu, index) in cartMenus" :key="index" class="flex justify-between cursor-pointer border-b-[1px] border-solid border-grey-50 py-[24px]">
                    <div class="flex gap-[8px]">
                        <div :style="getCartBackgroundStyle(menu.imageUrl, 60)"></div>
                        <div class="">
                            <div class="font-semibold">{{ menu.name }}</div>
                            <div class="text-caption text-grey-200">{{ menu.ingredient }}</div>
                        </div>
                    </div>

                    <div class="text-caption text-right grid gap-[8px]">
                        <div class="">{{ menu.price }}</div>
                        <div class="text-grey-200">Qty: 1</div>
                    </div>
                </div>

                <div class="mt-[42px] mb-[20px] inline-block">Do you have an existing device?</div>
                
                <div class="flex gap-[16px] text-grey-200">
                    <RadioButton value="Pick-up" />
                    <RadioButton value="Delivery" />
                </div>

                <div class="text-caption text-grey-200 mt-[22px]">
                    Address:<br />
                    816 Mission St, San Francisco, CA.<br />
                    (0801 2345 6789)
                </div>

                <div class="border-t-[1px] border-solid border-grey-50 py-[24px] mt-[32px] grid gap-[16px]">
                    <div class="flex items-center justify-between">
                        <div class="text-grey-200 text-body-2">Sub Total</div>
                        <div class="">N24,400</div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="text-grey-200 text-body-2">Discount</div>
                        <div class="">N0</div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="">Total</div>
                        <div class="">N24,400</div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="h-[90vh] overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px]">
                    <div class="font-medium">Payment Details</div>
                    <div class="font-medium text-caption text-grey-200">Complete your purchase by providing your information and  make payment</div> 

                    <div class="grid gap-[8px] my-[42px]">
                        <InputField
                            type="text"
                            label="Full Name"
                            name="email"
                        />

                        <InputField
                            type="text"
                            label="Email"
                            name="email"
                        />
                        <InputField
                            type="text"
                            label="Phone Number"
                            name="phone"
                        />
                    </div>

                    <div class="mb-[42px]">
                        <div class="font-medium">Payment Details</div>
                        <div class="grid gap-[16px] text-grey-200 text-body-2 mt-[16px]">
                            <RadioButton value="Pay with Bank Transfer (Recommended)" />
                            <RadioButton value="Pay with USSD" />
                            <RadioButton value="Pay with Direct Bank Charge" />
                        </div>
                    </div>

                    <ActionButton theme="primary" label="Make Payment" class="whitespace-nowrap" />
                </div>
            </div>
        </main>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';

const getCartBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const cartMenus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },
]);

</script>

<style scoped>

</style>