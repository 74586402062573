<template>
    <div class="star-rating">
      <span
        v-for="(star, index) in stars"
        :key="index"
        class="star"
        :class="{ filled: index < rating }"
        @click="selectRating(index + 1)"
        @mouseover="hoverRating(index + 1)"
        @mouseleave="resetHover"
      >
        ★
      </span>
    </div>
  </template>
  
  <script lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  
  export default defineComponent({
    name: 'StarRating',
    props: {
      modelValue: {
        type: Number,
        default: 0,
      },
      maxStars: {
        type: Number,
        default: 5,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const rating = ref(props.modelValue);
      const hoverValue = ref(0);
  
      const selectRating = (value: number) => {
        rating.value = value;
        emit('update:modelValue', value);
      };
  
      const hoverRating = (value: number) => {
        hoverValue.value = value;
      };
  
      const resetHover = () => {
        hoverValue.value = 0;
      };
  
      const stars = computed(() => {
        return hoverValue.value ? hoverValue.value : rating.value;
      });
  
      return {
        rating,
        hoverValue,
        selectRating,
        hoverRating,
        resetHover,
        stars: Array(props.maxStars).fill(null),
      };
    },
  });
  </script>
  
  <style scoped>
  .star-rating {
    display: inline-flex;
    gap: 8px;
    cursor: pointer;
  }
  .star {
    font-size: 24px;
    color: #ccc;
    transition: color 0.3s;
  }
  .star.filled {
    color: gold;
  }
  </style>
  