import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 1,
  class: "font-[500] text-body-2 w-[fit-content] m-[auto]"
}

import { Icon } from '@iconify/vue';
import { VNode } from 'vue';

interface Props {
    label: string | VNode
    theme?: 'primary' | 'secondary' | 'outlined' | 'linked'
    prefixIcon?: any
    suffixIcon?: any
    handleClick?: any
    isSubmit?: boolean
    disable?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionButton',
  props: {
    label: {},
    theme: {},
    prefixIcon: {},
    suffixIcon: {},
    handleClick: {},
    isSubmit: { type: Boolean },
    disable: { type: Boolean }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["w-[100%] flex justify-between py-[10px] px-[19px] rounded-[6px] gap-[10px]", {
            'bg-primary-300 text-bg-100 border-none': _ctx.theme == 'primary', 
            'bg-bg-200 text-grey-300 border-none': _ctx.theme == 'secondary',  
            'bg-transparent border-solid border-2 border-bg-300 ': _ctx.theme == 'outlined', 
            'text-[#1677FF] border-none bg-transparent': _ctx.theme == 'linked',
            'bg-grey-100 opacity-75 cursor-not-allowed': _ctx.disable == true
        }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    type: _ctx.isSubmit ? 'submit' : 'button',
    disabled: _ctx.disable
  }, [
    (_ctx.prefixIcon)
      ? (_openBlock(), _createBlock(_unref(Icon), {
          key: 0,
          class: _normalizeClass(["text-grey-200", {
                'text-white': _ctx.theme == 'primary',
                'text-[#1677FF]': _ctx.theme == 'linked'
            }]),
          icon: _ctx.prefixIcon,
          width: "24",
          height: "24"
        }, null, 8, ["class", "icon"]))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.suffixIcon)
      ? (_openBlock(), _createBlock(_unref(Icon), {
          key: 2,
          class: _normalizeClass(["text-grey-200", {
                'text-bg-100': _ctx.theme == 'primary',
                'text-[#1677FF]': _ctx.theme == 'linked'
            }]),
          icon: _ctx.suffixIcon,
          width: "24",
          height: "24"
        }, null, 8, ["class", "icon"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})