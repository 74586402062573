import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-primary-300" }
const _hoisted_2 = { class: "lg:w-[50%] mx-auto" }
const _hoisted_3 = { class: "bg-primary-300 border-r-[1px] border-grey-50 border-l-[1px] px-[32px] py-[60px]" }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = { class: "flex items-center gap-[6px] w-fit bg-white rounded-[8px]" }
const _hoisted_6 = { class: "text lg:w-[50%] mx-auto border-[1px] border-grey-50 px-[32px] pt-[60px] rounded-lg" }
const _hoisted_7 = { class: "flex gap-[16px]" }
const _hoisted_8 = { class: "text-grey-200" }
const _hoisted_9 = { class: "flex gap-[16px] my-[14px]" }
const _hoisted_10 = { class: "text-grey-200" }
const _hoisted_11 = { class: "flex items-center gap-[16px]" }
const _hoisted_12 = { class: "text-grey-200" }
const _hoisted_13 = { class: "flex gap-[16px] mt-[24px]" }
const _hoisted_14 = { class: "text-grey-200 w-fit rounded-full border-[1px] border-grey-50 overflow-hidden" }

import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'OrderReceipt',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("header", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/logos/foodcart.png'),
                width: "62",
                height: "62"
              }, null, 8, ["imageUrl"])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-white" }, [
              _createElementVNode("div", { class: "font-medium" }, "Total: N 46, 700"),
              _createElementVNode("div", { class: "text-caption mt-[10px]" }, "Thursday, 30 May 2024")
            ], -1))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-heading-3 font-semibold text-white mt-[56px] mb-[14px]" }, "Thanks for choosing us, Toheeb.", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-medium text-white" }, "We hope you enjoyed your food.", -1))
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_6, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-body-2" }, " Your order (ID: IKZ97HUXW) was just dropped off at the delivery address you requested. We hope everything went smoothly. Please find your order details below: ", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex items-center gap-[6px] bg-white my-[24px]" }, [
        _createElementVNode("div", {
          class: "",
          style: {"width":"100%","height":"120px","background-image":"url('https://s3-alpha-sig.figma.com/img/b77b/e80f/61d91e84eab1c348a5e7d9cc921dae4a?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KnjovEZLPzC2stqz3iN9hEOjvr3hMJDThra5dgUl75piSEzgbsHNZYZSxlqczZAiUqhjSIpdxu37zp1g1ZShxqvIUoB4ihrjh~AQ9Ep-B6C71w5IJ3unrHOqO~rjlcdHyk2MDlmp~QdkochnSEsrDIwe-7E3yJHzxmIUrtuK1ldk8gAFwP9juK9soki1MJ2kERdDiVgRuft9wwSQPcysr4sXb4quZEJgixLcKt91uEmzJeFywgA1O~-3Y2Ek8Tz8WN2uQdQLRnDkGWwm4S0oEcTsplkFe7oP9srMYQiVXka~scmlFWjgLkKM4ZuO~JJxKCK8eOwzuRkT~1IllL2M9Q__')","background-repeat":"no-repeat","background-size":"cover","background-position":"center","border-radius":"8px"}
        })
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(Icon), {
            icon: "mdi:map-marker-outline",
            width: "24",
            height: "24"
          })
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "" }, [
          _createElementVNode("div", { class: "font-medium" }, "Bo Cafe"),
          _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Plot 16, Ojuelegab, Lagos, Nigeria.")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:flag-outlined",
            width: "24",
            height: "24"
          })
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "" }, [
          _createElementVNode("div", { class: "font-medium" }, "Old Abeokuta Road, 292"),
          _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "green hill estate, opposite tabantabn junction. Agegé")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:calendar-outlined",
            width: "24",
            height: "24"
          })
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "" }, [
          _createElementVNode("div", { class: "text-grey-200 text-body-2" }, "Delivery time: Jan 25, 2024 5:40 PM")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(ImageCard, {
            imageUrl: require('@/assets/images/images/profile-pic.jpeg'),
            width: "62",
            height: "62"
          }, null, 8, ["imageUrl"])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "" }, [
          _createElementVNode("div", { class: "font-medium" }, "Old Abeokuta Road, 292"),
          _createElementVNode("div", { class: "text-grey-200 text-body-2 mt-[4px]" }, "green hill estate, opposite tabantabn junction. Agegé")
        ], -1))
      ]),
      _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"mt-[24px] border-t-[1px] border-b-[1px] border-grey-50 py-[24px]\"><div class=\"flex justify-between gap-[16px]\"><div class=\"flex gap-[16px]\"><div class=\"text-grey-200\">1x</div><div class=\"\"><div class=\"font-medium\">12 Pcs KFC Bucket</div><div class=\"text-grey-200 text-body-2\">Crunchy, Pepsi 500ml Pet Bottle x 2</div></div></div><div class=\"\">N 23,500.00</div></div><div class=\"flex justify-between gap-[16px] my-[14px]\"><div class=\"flex gap-[16px]\"><div class=\"text-grey-200\">1x</div><div class=\"\"><div class=\"font-medium\">2 liter of Egusi Soup</div><div class=\"text-grey-200 text-body-2\">Egusi soup</div></div></div><div class=\"\">N 23,500.00</div></div></div><div class=\"flex justify-between gap-[16px] my-[16px]\"><div class=\"font-medium\">Subtotal</div><div class=\"\">N 46,000.00</div></div><div class=\"flex justify-between gap-[16px]\"><div class=\"flex gap-[16px]\"><div class=\"\"><div class=\"font-medium\">Delivery</div><div class=\"text-grey-200 text-body-2\">Taxable amount ₦362.79 plus VAT (7.5%) ₦27.21</div></div></div><div class=\"\">N 600.00</div></div><div class=\"flex justify-between gap-[16px] my-[16px]\"><div class=\"flex gap-[16px]\"><div class=\"\"><div class=\"font-medium\">Services</div><div class=\"text-grey-200 text-body-2\">Taxable amount ₦93.02 plus VAT (7.5%) ₦6.98</div></div></div><div class=\"\">N 100.00</div></div><div class=\"flex justify-between gap-[16px] mt-[20px] border-t-[1px] border-grey-50 py-[24px]\"><div class=\"flex gap-[16px]\"><div class=\"\"><div class=\"font-medium\">Total paid</div><div class=\"text-grey-200 text-body-2\">Bank Transfer</div></div></div><div class=\"\">N 46,700.00</div></div>", 5))
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "my-[32px] p-[20px] font-medium text-body-2 text-grey-300 bg-[#FFF6BE] lg:w-[50%] mx-auto rounded-[8px]" }, [
      _createTextVNode(" This is an automated email. If you need help, please "),
      _createElementVNode("span", { class: "underline" }, "contact support here"),
      _createTextVNode(" instead of replying to this email. ")
    ], -1))
  ]))
}
}

})