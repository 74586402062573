import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "lg:px-[60px] px-[20px]" }
const _hoisted_2 = { class: "shadow-md rounded-[200px] bg-white relative" }
const _hoisted_3 = { class: "w-fit mx-auto pt-[32px] relative" }
const _hoisted_4 = { class: "lg:px-[60px] mt-[60px] mb-[-30px]" }
const _hoisted_5 = { class: "grid lg:grid-cols-4 lg:gap-[32px] gap-[20px] px-[20px]" }
const _hoisted_6 = { class: "p-[32px] text-center" }
const _hoisted_7 = { class: "font-semibold mt-[12px] text-heading-4" }
const _hoisted_8 = { class: "my-[20px] text-grey-200" }
const _hoisted_9 = { class: "flex items-center gap-[10px] mt-[28px]" }
const _hoisted_10 = { class: "px-[28px] pt-[32px]" }
const _hoisted_11 = { class: "text-body-1 text-grey-200 whitespace-pre-wrap" }
const _hoisted_12 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_13 = { class: "mt-[48px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
// import AppFaq from '@/components/shared/app-faq/AppFaq.vue';
import AppFooter from '@/components/shared/app-footer/AppFooter.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import AppNavbar from '@/components/shared/navbar/AppNavbar.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogView',
  setup(__props) {

const imageStyle = ref({
    height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.freepik.com%2Ffree-photo%2Fmedium-shot-woman-reading-coffee-shop_23-2150183723.jpg&f=1&nofb=1&ipt=da1acdcebe4df8352a14d44946aaf761e9eb44b275eb536b95efd8cbe24be3cd&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const restaurants = ref([
    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'Restaurant on steroids',
        location: "I am a restaurant. And I am going to tell you what I'm about to tell you because somehow you're connected to food. For me, being connected to food isn't just enough yet, caring about good food and anything that makes it good is the real deal! Okay.",
        content: (`I am a restaurant. And I am going to tell you what I'm about to tell you because somehow you're connected to food. For me, being connected to food isn't just enough yet, caring about good food and anything that makes it good is the real deal! Okay.

I made a discovery. This discovery has switched on my steroids mode and I'm no regular restaurant anymore. Enough of the suspense… Have you ever imagined an ecosystem for restaurants? A restaurant's tightly interconnected heaven! Let me show you one.

Now picture yourself, a restaurant owner, being augmented by tech all through your restaurant journey. Yes-all through. I mean, right from the moment you start considering entering the business all through till you're established.

Being new in the food business, you are really confused with where to start and how not to get short-changed. You thought it was just to open up a building and start selling food but reality seems to be proving you wrong. At the end of the day, it's a business to - has risks and you can't be too certain of your predictions. Just when you were giving up, you discovered my discovery and activated your steroids levels. You now have professionals who know the ins-and-outs of the food world and hold your hand all through the journey. Their experience becomes your tool and there's almost no threat that your business could fail. In a few weeks, you are up and running, like a professional, well grounded in the art of touching hearts with healthy meals.

…

Few months down the line, your restaurant is growing and it seems you really can't keep up with the speed of this growth. Some foods sell better than others. Some customers frequent more than others. Some days have more sales than others. There's a trend but you don't see it yet. You now have numerous customers frequenting your lovely food place. You should be happy. But you're frustrated because you're always short-staffed. No matter how many more employees you get, it keeps seeming like you need more. And you don't think it's good business to employ yet more workers to the batch you just employed last week. You can sense you need more but it's definitely not workers. Amidst your frustrations, you remember your friends who got you started - your discovery. Alas, they had more for you to discover. This time, this new discovery is tailored towards solving your exact predicament. You discover the Registered System. That was the solution all along.

With the Registered System, a tech solution to common restaurant problems, you are able to understand market trends, your restaurant trends, what kind of food your customers like, your customers and more. Let me give you a little context here: I started running a promo last week. How much impact has it had on my business so far? Should I stop the promo or continue? Am I losing customers? What kind of food do my customers like best? What food on the menu do they seldom buy? Do I need to reduce the quantity of such food I prepare daily?Which customer has a routine of coming when? Have they stopped coming? Do I need to reach out to them? What's my total revenue so far? What do I spend on most? Can I see and access all that's going on in my restaurant even when I'm not there? Does that mean I can run multiple restaurants at once with little to worry about? Can I have exclusive access to some of my restaurant data?

With the registered system, you get all positive answers to your questions. Having questions like this answered significantly maximizes your profit and eliminates the losses. The Registered System, your newest discovery, gave you the answers. All the answers and more that you never thought to ask.

But the Registered System has yet more to offer - a payment system! A freaking bad-ass payment system! Your customers do not have to come in with cash. They can make seamless and fast payment for their orders with their cards. It gets even easier. The receipts for every transaction are generated and signed by the System itself. You can't ever be scammed.

There's a lot more to discover about how much more solutions FoodCart has to offer your business as a restaurant owner but trust me, I'll make a sequel to this and show you more. And oh! Let me give you a spoiler alert on what my discovery is. It's FoodCart. My restaurant start-up consultancy friends, the amazing Registered System and many more I'll harness in coming sequels, it's all FoodCart saving the business in varying morphs.

I discovered FoodCart and I became a restaurant on steroids.`)
    },

    {
        imageUrl: require('@/assets/images/images/foodcartCart.png'),
        name: 'Restaurant on steroids (II)',
        location: "I am a restaurant. Remember me? I was telling you about my discovery the last time but I kept the best for the last. Now that your restaurant has fearlessly braved through the stormy beginnings and you feel the burning urge to get even better. ",
        content: `I am a restaurant. Remember me? I was telling you about my discovery the last time but I kept the best for the last. Now that your restaurant has fearlessly braved through the stormy beginnings and you feel the burning urge to get even better. Of course, a restaurant that stops improving becomes a dinosaur! You know this well but you seem to have had all your needs catered for. You ask and re-ask yourself in wobbly doubts: "Can this get any better?". Little do you know that your friends, your discovery have the answer you seek. "Yes, there's always room, with FoodCart, to get legendarily good!", they tell you reassuringly. You were awestruck by the payment system already and waited readily to discover what more improvement there could be.

Before I bombard you with what FoodCart has in the pipeline still journeying their way to you, let's take a moment to talk about the FoodCart microsite which is already here and how it's the next level you seek for your business


You know how you have always wanted a way to access customers that are not physically present with you and how you wish they could access your menu in an interactive manner. You know how you wish that these stranger customers, who form the large percentage of your potential customer base could become actual customers and you thereby become limitless in your distribution? You know how you wish that these potential customers could buy your stuff online, you receive their orders and make the delivery? You know how the "but" that restricts the actualization of your wishes is having to pay through the nose for a software/tech team to build your e-commerce? That's the context of the Microsite. With the microsite, you have your restaurant practically online and easily accessed just with the click of your dedicated link. Your customers make their orders seamlessly and you handle the delivery.

You feel so wowed. This was it! You were at the next level that you sought. You tried it out and your sales doubled in the next couple of weeks. Several weeks into this and you're now used to multiple folds of sales than you used to have when you thought that was the best it could ever get. FoodCart is happy that you're happy. That's what they exist for, afterall to put a never-ending excitement on your face.

Just when we all agree that this is the height of it. FoodCart has been amazing! As you bask in the glory of your most recent wins, FoodCart goes back to the drawing board to chart out ways of improving your experience yet again, driving home the fact that it's indeed the restaurants' heaven.

Your discovery has now come up with new solutions and they're actively working to bring it to you without delay. You can't wait for it! Why? Because now, you can buy your food materials from a centralized Marketplace at actual wholesales prices and get them delivered to you. You can have your daily food materials on-demand without hassle just by browsing through the Marketplace and making your orders. What more. You really can't wait because… (drumroll…)… FoodCart would be handling your deliveries! All the bottlenecks and hassles involved in having your customers receive their orders would be lifted off you. And now, even if you wanted to be a ghost restaurant 🥲, you can be the ideal ghost restaurant attaining your full ghost potentials.`
    },
]);

const isModalOpen = ref(false);
const selectedRestaurant = ref();

const openModal = (restaurant: any) => {
    selectedRestaurant.value = restaurant; 
    isModalOpen.value = true; 
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedRestaurant.value = null; 
};

const getBackgroundStyle = (imageUrl: string) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px', // Adjust the height as per your design
    width: '100%',
    borderRadius: '8px 8px 0 0'
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle(imageStyle.value),
      class: "lg:px-[60px] py-[24px] relative"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AppNavbar)
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-sourceSerifPro lg:text-[80px] text-[45px] text-center lg:mt-[260px] mt-[150px] text-white font-bold relative" }, [
        _createElementVNode("div", { class: "px-[60px] lg:flex justify-center gap-[20px]" }, [
          _createElementVNode("div", { class: "" }, "Blog &"),
          _createElementVNode("div", { class: "bg-white text-primary-300 lg:px-[20px] rounded-[16px]" }, "Stories")
        ])
      ], -1)),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/16.png'),
        width: "140",
        height: "140",
        class: "absolute top-[200px] right-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _createVNode(ImageCard, {
        imageUrl: require('@/assets/images/icons/17.png'),
        width: "140",
        height: "140",
        class: "absolute bottom-[50px] left-[60px] lg:block hidden"
      }, null, 8, ["imageUrl"]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "px-[60px] lg:w-[811.82px] mx-[auto] text-center lg:text-heading-4 text-heading-6 lg:mt-[40px] mt-[20px] text-[#FFFFFFBF] relative" }, " The good stuff. Follow our social media for the latest updates, features and even sneak peeks 👀 ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/registration" }, {
          default: _withCtx(() => [
            _createVNode(ActionButton, {
              theme: "primary",
              label: "Register Now",
              class: "rounded-[99.9px]"
            })
          ]),
          _: 1
        })
      ])
    ], 4),
    _createElementVNode("main", _hoisted_4, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"whitespace-nowrap overflow-auto px-[20px] no-scrollbar\"><div class=\"flex lg:gap-[24px] gap-[10px] mb-[32px] border-b-[1px] w-fit font-medium text-grey-100\"><div class=\"p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer\">All</div><div class=\"p-[16px] cursor-not-allowed\">News</div><div class=\"p-[16px] cursor-not-allowed\">Marketings</div><div class=\"p-[16px] cursor-not-allowed\">Vendors</div><div class=\"p-[16px] cursor-not-allowed\">Operations</div></div></div>", 1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(restaurants.value, (restaurant, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "border-[1px] border-grey-50 rounded-[8px]"
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(getBackgroundStyle(restaurant.imageUrl))
            }, null, 4),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(restaurant.name), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(restaurant.location), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(ActionButton, {
                  theme: "secondary",
                  label: "Read More",
                  onClick: ($event: any) => (openModal(restaurant))
                }, null, 8, ["onClick"])
              ])
            ])
          ]))
        }), 128))
      ]),
      _createVNode(AppModal, {
        headerContent: selectedRestaurant.value?.name,
        openModal: isModalOpen.value,
        onClose: closeModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(selectedRestaurant.value?.content), 1)
          ]),
          _createElementVNode("footer", _hoisted_12, [
            _createVNode(ActionButton, {
              label: "Close",
              theme: "secondary",
              onClick: closeModal,
              class: "w-fit"
            })
          ])
        ]),
        _: 1
      }, 8, ["headerContent", "openModal"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(AppFooter)
    ])
  ]))
}
}

})