import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "star-rating" }
const _hoisted_2 = ["onClick", "onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stars, (star, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass(["star", { filled: index < _ctx.rating }]),
        onClick: ($event: any) => (_ctx.selectRating(index + 1)),
        onMouseover: ($event: any) => (_ctx.hoverRating(index + 1)),
        onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetHover && _ctx.resetHover(...args)))
      }, " ★ ", 42, _hoisted_2))
    }), 128))
  ]))
}