<template>
    <div class="">
        <header class="flex lg:px-[60px] py-[18px] border-b-[1px] justify-between" >
            <router-link to="/">
                <div class="">
                    <div class="flex items-center gap-[6px] w-fit px-[20px] py-[3px]">
                        <ImageCard :imageUrl="require('@/assets/images/logos/foodcart.png')" width="48" height="48" />
                        <div class="font-itim text-heading-5">Foodcart</div>
                    </div>
                </div>
            </router-link>

            <div class="grid items-center gap-[10px]" style="grid-template-columns: 1fr auto auto">
                <div class="lg:block hidden">
                    <InputField type="text" prefixIcon="ant-design:search-outlined" />
                </div>

                <div class="">
                    <ActionButton theme="primary" prefixIcon="ant-design:shopping-filled" label="0" class="w-fit whitespace-nowrap" :handleClick="openSideModal" />
                </div>

                <div class="lg:block hidden relative">
                    <ActionButton theme="outlined" prefixIcon="ant-design:user-outlined" label="Account" class="w-fit whitespace-nowrap" @click="toggleDropdown" />

                    <!-- Unauthenticated -->
                    <div v-if="isDropdownOpen" class="absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50">
                        <div class="flex justify-between gap-[8px] px-[16px]  mb-[10px]">
                            <ActionButton theme="outlined" label="Login" class="whitespace-nowrap" @click="openLoginModal" />
                            <ActionButton theme="primary" label="Sign Up" class="whitespace-nowrap" @click="openSignUpModal" />
                        </div>
                        <div class="border-b-[1px] border-grey-50 relative">
                            <div class="mx-auto mb-[-12px] bg-white px-[8px] w-fit">or</div>
                        </div>
                        <div class="px-[16px] mt-[20px]">
                            <ActionButton theme="outlined" prefixIcon="ant-design:google-outlined" label="Continue with Google" class="w-fit whitespace-nowrap" />
                        </div>
                    </div>

                    <!-- Authenticated -->
                    <!-- <div v-if="isDropdownOpen" class="absolute top-[60px] right-0 z-40 bg-white py-[16px] rounded-[8px] border-[1px] border-grey-50 w-[300px]">
                        <div>
                            <div v-for="(item, index) in dropdownItems" :key="index" class="flex items-center gap-[8px] text-grey-200 font-medium py-[11px] px-[24px]" :class="item.class" @click="item.action">
                                <Icon :icon="item.icon" class="text-sub-heading " />
                                <div class="cursor-pointer">{{ item.text }}</div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="lg:hidden">
                    <ActionButton theme="outlined" prefixIcon="ant-design:menu-outlined" label="" class="w-fit whitespace-nowrap" />
                </div>
            </div>

            <SideModel :openModal="isSideModalOpen" @close="closeSideModal" header-content="Shopping Cart">
                <div class="mt-[80px]">
                    <small class="p-[24px]">Order Summary</small>
                    <div class="">
                        <div v-for="(menu, index) in cartMenus" :key="index" class="rounded-[8px] cursor-pointer grid gap-[8px] border-b-[1px] border-solid border-grey-50 p-[24px]" @click="openModal(menu)">
                            <div class="flex gap-[8px]">
                                <div :style="getCartBackgroundStyle(menu.imageUrl, 60, 60)"></div>
                                <div class="">
                                    <div class="font-semibold">{{ menu.name }}</div>
                                    <div class="text-caption text-grey-200">{{ menu.ingredient }}</div>
                                </div>
                            </div>

                            <div class="flex items-center justify-between">
                                <div class="text-body-2 font-medium">{{ menu.price }}</div>
                                <div class="bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]">
                                    <div class="bg-white rounded-full w-[32px] h-[32px] relative" @click="decrementQuantity">
                                        <Icon icon="ant-design:minus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                    </div>
                                    <div class="">{{ quantity }}</div>
                                    <div class="bg-white rounded-full w-[32px] h-[32px] relative" @click="incrementQuantity">
                                        <Icon icon="ant-design:plus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bg-grey-50">
                        <div class="px-[24px] py-[16px] border-b-[1px] border-solid border-grey-50 flex items-center justify-between text-grey-200">
                            <div class="text-body-2">Did you get everything</div>
                            <Icon icon="ant-design:caret-down-filled" width="18" height="18" @click="toggleListVisibility" />
                        </div>
                        <div v-if="isListVisible">
                            <div v-for="(menu, index) in cartMenus" :key="index" class="cursor-pointer flex justify-between border-b-[1px] border-solid border-grey-50 p-[24px]">
                                <div class="flex gap-[8px]">
                                    <div :style="getCartBackgroundStyle(menu.imageUrl, 60, 60)"></div>
                                    <div class="font-semibold">{{ menu.name }}</div>
                                </div>

                                <div class="grid items-center gap-[8px]">
                                    <div class="text-body-2 font-medium">{{ menu.price }}</div>
                                    <div class="bg-white rounded-full w-[32px] h-[32px] relative">
                                        <Icon icon="ant-design:plus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex items-center justify-between p-[24px]">
                        <div class="text-caption text-grey-100">Subtotal (excluding taxes):</div>
                        <div class="font-bold">N15,400.00</div>
                    </div>

                    <div class="border-t-[1px] border-solid border-grey-50 px-[24px] py-[24px]">
                        <router-link to="/checkout">
                            <ActionButton theme="primary" label="Checkout" class="whitespace-nowrap" />
                        </router-link>
                    </div>
                </div>
            </SideModel>
        </header>

        <div :style="imageStyle" class="lg:px-[60px] py-[24px] relative">
            <div class="absolute inset-0 bg-black opacity-70"></div>

            <div class="lg:flex gap-[16px] lg:w-[1000px] m-auto pt-[63px] pb-[50px] relative">
                <div class="">
                    <ImageCard :imageUrl="require('@/assets/images/images/bo-cafe.jpeg')" width="100" height="100" class="w-fit mx-auto rounded-full border-[2px] border-white overflow-hidden" />
                </div>

                <div class="relative text-white">
                    <div class="text-heading-4 font-bold lg:text-left text-center">Bo Cafe</div>
                    <div class="text-caption flex gap-[8px] items-center w-fit mx-auto lg:mx-0">
                        <StarRating :maxStars="5" />
                        <div class="">3.0 (505 price)</div>
                    </div>
                    <div class="mb-4 text-body-2 lg:text-left text-center lg:px-0 px-[16px]">
                        Hailing from a Punjabi family in India, Chef Arveen's upbringing immersed her in the rich tapestry of Indian flavours and traditions. 
                        Inspired by her mother and mother ...see more
                    </div>
                    <div class="lg:flex items-center text-caption gap-[8px] w-fit mx-auto lg:mx-0">
                        <ActionButton label="Opened" class="w-fit whitespace-nowrap bg-success text-white text-caption w-fit mx-auto lg:mx-0 mb-2 lg:mb-0" />
                        <div>Opening time: 7:00 AM to 11:00 PM</div>
                    </div>
                </div>
            </div>

            <div class="px-[13px] pb-[24px] relative lg:block hidden">
                <ActionButton theme="outlined" label="Contact Us" class="w-fit whitespace-nowrap ml-auto relative text-white border-white" />
            </div>
        </div>

        <main class="pt-[32px] w-[100%]">
            <div ref="navBar" :class="{ 'fixed-top': isFixed }" class="whitespace-nowrap overflow-auto no-scrollbar">
                <div class="flex lg:gap-[24px] gap-[10px] border-b-[1px] lg:px-[60px] font-medium text-grey-100 text-body-2">
                    <div class="p-[16px] border-b-[2px] border-primary-300 text-grey-300 cursor-pointer">African Soup</div>
                    <div class="p-[16px] cursor-not-allowed">Break-fast</div>
                    <div class="p-[16px] cursor-not-allowed">Cupcake</div>
                    <div class="p-[16px] cursor-not-allowed">Pepper soup</div>
                    <div class="p-[16px] cursor-not-allowed">Drinks</div>
                </div>
            </div>

            <div class="bg-[#f0f0f0]">
                <div class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 lg:px-[60px] py-[32px]">
                    <div v-for="(menu, index) in menus" :key="index" class="lg:p-[12px] p-[6px] rounded-[8px] cursor-pointer" @click="openModal(menu)">
                        <div :style="getBackgroundStyle(menu.imageUrl, 200)"></div>
                        <div class="font-semibold mt-[12px]">{{ menu.name }}</div>
                        <div class="my-[12px] text-caption text-grey-200">{{ menu.price }}</div>
                    </div>
                </div>
            </div>

            <!-- Login Modal -->
            <div v-show="isLoginModalOpen" class="modal-overlay">
                <div class="modal-conten w-[550px] h-full overflow-auto relative no-scrollbar">
                    <div class="bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <div class="text-grey-300 text-sub-heading">Login</div>
                        <div class="grid gap-[8px] mt-[16px]">
                            <InputField type="email" label="email" />
                            <InputField type="password" label="Password" />
                        </div>
                        <div class="text-right text-caption text-grey-200 underline mb-[16px] mt-[8px]">Forget Password</div>

                        <ActionButton theme="primary" label="Login" class="whitespace-nowrap mb-[16px]" />

                        <div class="flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]">
                            <div class="text-caption text-grey-200 underline">Don’t have an account?</div>
                            <div class=" text-caption text-grey-200 underline">
                                <ActionButton theme="outlined" label="Signup" class="whitespace-nowrap" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sign Up Modal -->
            <div v-show="isSignUpModalOpen" class="modal-overlay">
                <div class="modal-conten w-[550px] h-full overflow-auto relative no-scrollbar">
                    <div class="bg-white p-[16px] rounded-[8px] absolute w-full left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <div class="text-grey-300 text-sub-heading">Sign Up</div>
                        <div class="grid gap-[8px] my-[16px]">
                            <div class="grid grid-cols-2 items-center gap-[16px]">
                                <InputField type="text" label="First Name" />
                                <InputField type="text" label="Last Name" />
                            </div>
                            <InputField type="email" label="Email" />
                            <InputField type="password" label="Password" />
                        </div>
                        <ActionButton theme="primary" label="Sign Up" class="whitespace-nowrap mb-[16px]" />

                        <div class="flex items-center justify-between border-t-[1px] border-grey-50 pt-[16px]">
                            <div class="text-caption text-grey-200 underline">Already have an account?</div>
                            <div class=" text-caption text-grey-200 underline">
                                <ActionButton theme="outlined" label="Login" class="whitespace-nowrap" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AppModal :openModal="isModalOpen" @close="closeModal">
                <div class="relative">
                    <div :style="getBackgroundStyle(selectedMenuDetails?.imageUrl, 300)"></div>
                    <div @click="closeModal" class="absolute right-[20px] top-[20px] bg-black opacity-70 w-[32px] h-[32px] rounded-[8px] border-[1px] border-white cursor-pointer">
                        <Icon icon="ant-design:close-outlined" width="18" height="18" class="text-white absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                    </div>
                </div>

                <div class="flex items-center justify-between lg:px-[32px] px-[10px] pt-[24px]">
                    <div class="font-semibold text-heading-4">{{ selectedMenuDetails?.name }}</div>
                    <div class="text-body-2 font-medium text-grey-300">{{ selectedMenuDetails?.price }}</div>
                </div>

                <div class="text-grey-200 font-medium border-b-[1px] border-solid border-grey-50 py-[24px] lg:px-[32px] px-[10px]">
                    Tender pieces of chicken simmered in a rich, house-made, tomatoes and cashew nut based gravy. 
                    Perfectly paired with Sooper's Roti, Kulcha, or Parantha flatbreads. 
                </div>

                <div class="flex items-center justify-between lg:px-[32px] px-[10px] py-[16px]">
                    <div class="font-semibold text-body-2">Quantity: </div>
                    <div class="bg-grey-50 flex gap-[16px] items-center justify-between p-[6px] rounded-[42px] w-[120px]">
                        <div class="bg-white rounded-full w-[32px] h-[32px] relative" @click="decrementQuantity">
                            <Icon icon="ant-design:minus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                        </div>
                        <div class="">{{ quantity }}</div>
                        <div class="bg-white rounded-full w-[32px] h-[32px] relative" @click="incrementQuantity">
                            <Icon icon="ant-design:plus-outlined" width="18" height="18" class="text-grey-200 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
                        </div>
                    </div>
                </div>

                <TextArea label="Add a note" placeholder="Add a note to your order (optional)" class="lg:px-[32px] px-[10px]" />

                <div class="flex items-center gap-[16px] border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] py-[24px] mt-[24px]">
                    <ActionButton theme="primary" label="Add to Cart (N1,200)" class="whitespace-nowrap" />
                    <ActionButton label="Buy Now (N1,200)" class="whitespace-nowrap bg-grey-50 text-grey-300" />
                </div>
            </AppModal>

            <!-- Orders Modal -->
            <AppModal header-content="Orders" :openModal="isOrdersModalOpen" @close="closeOrdersModal">
                <div class="mt-[100px] flex items-center mx-[20px] text-body-2 gap-[32px] border-b-[1px] border-grey-50 text-grey-200">
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Ongoing'}" class='py-[12px] cursor-pointer' @click="changeTab('Ongoing')">Ongoing</div>
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Delivered'}" class='cursor-pointer' @click="changeTab('Delivered')">Delivered</div>
                    <div :class="{'text-primary-300 border-b-[2px] border-primary-300': currentTab === 'Cancelled'}" class='cursor-pointer' @click="changeTab('Cancelled')">Cancelled/Returned</div>
                </div>

                <div v-for="(order, index) in orderStatuses[currentTab as keyof typeof orderStatuses]" :key="index" class="rounded-[8px] cursor-pointer grid gap-[8px] border-[1px] border-solid border-grey-50 m-[20px]">
                    <div class="flex gap-[8px] p-[10px]">
                        <div :style="getCartBackgroundStyle(order.imageUrl, 84, 84)"></div>
                        <div class="">
                            <div class="font-semibold">{{ order.name }}</div>
                            <div class="text-caption text-grey-200">{{ order.ingredient }}</div>
                            <div :class="getStatusColorClass(order.status)" class="px-[8px] py-[4px] rounded-[4px] w-fit text-caption mt-[14px]">{{ order.status }}</div>
                        </div>
                    </div>

                    <div class="bg-grey-50 py-[4px] px-[8px] flex items-center justify-between text-caption rounded-b-[8px]">
                        <div>{{ order.deliveryDate }}</div>
                        <div v-if="currentTab != 'Cancelled' && 'receipt' in order" class="text-grey-200">
                            <router-link to="/receipt">{{ order.receipt }}</router-link>
                        </div>
                    </div>
                </div>
            </AppModal>
        </main>
    </div>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ImageCard from '@/components/shared/image/ImageCard.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import SideModel from '@/components/shared/modal/SideModel.vue';
import StarRating from '@/components/shared/ratings/StarRating.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import { Icon } from '@iconify/vue';
import { ref, onMounted, onUnmounted } from 'vue';

const isModalOpen = ref(false);
const selectedMenuDetails = ref();

const isSideModalOpen = ref(false);

const openSideModal = () => {
    isSideModalOpen.value = true; 
};

const closeSideModal = () => {
    isSideModalOpen.value = false; 
};

const openModal = (menus: any) => {
    selectedMenuDetails.value = menus; 
    isModalOpen.value = true; 
    isDropdownOpen.value = false;
};

const imageStyle = ref({
    // height: '100vh',
    backgroundImage: `url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.stirworld.com%2Fimages%2Farticle_gallery%2Fzaytinya-is-a-new-eatery-by-two-star-michelin-chef-jos-eacute-andr-eacute-s-zaytinya-rockwell-group-stirworld-220929043534.jpg&f=1&nofb=1&ipt=201842be87996a6b5946c2b16a387039b495525223f509fb5d9f4463f75c3799&ipo=images)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // filter: 'blur(1px)'
});

const getBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const getCartBackgroundStyle = (imageUrl: string, height?: number, width?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: `${width}px` || '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});


const menus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fnomadparadise.com%2Fwp-content%2Fuploads%2F2020%2F04%2Fsa-food-005-1024x640.jpg&f=1&nofb=1&ipt=c95b0e6b885aa6b034de6d5b73586cd1adf5499db1fb684380c6ed4e93a2e3e1&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fb5%2F3e%2F48%2Fb53e481091c747f79808459a3db764c3.jpg&f=1&nofb=1&ipt=f5257e5207d9140cb2487f73e7c3befd32a16c75196f1f94bc025712cb2bb368&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fsisijemimah.com%2Fwp-content%2Fuploads%2F2015%2F12%2FNigerian-Assorted-peppered-meats-10.jpg&f=1&nofb=1&ipt=ca2e170b97e09d76a97d12c7cab7958ce2c6abeca07498cd43570fd2b5fd73f8&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F83%2F8e%2F31%2F838e31fb16313577a72f4dde938f0702.jpg&f=1&nofb=1&ipt=27d5a5c9b671c641dc526337d04ef743dafb841c4dc8a5ba9acd945ab9e29629&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fbd%2F06%2Fe3%2Fbd06e3b4de358b7c5700150e987b2850.jpg&f=1&nofb=1&ipt=a30e9a774659d0d751a48548fe235f32f46ce8c2fbb0cf1d914b79cfeeb153b2&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        location: 'Adegbola, 46 Oyemekun St, Ifako Ikeja, Lagos 101232, Lagos.',
        price: 'N1,800'
    },
]);

const cartMenus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },
]);

const isFixed = ref(false);
const navBar = ref<HTMLElement | null>(null);
let navBarOffsetTop = 0;

const handleScroll = () => {
    if (navBar.value) {
        const currentScroll = window.scrollY;
        isFixed.value = currentScroll >= navBarOffsetTop;
    }
};

onMounted(() => {
    if (navBar.value) {
        navBarOffsetTop = navBar.value.offsetTop; 
    }
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

const quantity = ref(1);

const incrementQuantity = () => {
    quantity.value += 1;
};

const decrementQuantity = () => {
    if (quantity.value > 1) { 
        quantity.value -= 1;
    }
};

const isListVisible = ref(true);

const toggleListVisibility = () => {
    isListVisible.value = !isListVisible.value;
};

const isDropdownOpen = ref(false);

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};

const isLoginModalOpen = ref(false);
const isSignUpModalOpen = ref(false);

const openLoginModal = () => {
    isLoginModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const openSignUpModal = () => {
    isSignUpModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeModal = () => {
    isModalOpen.value = false; 
    selectedMenuDetails.value = null; 
};


const isOrdersModalOpen = ref(false);
const currentTab = ref('Ongoing'); 

const orderStatuses = {
    Ongoing: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Waiting to be shipped',
            deliveryDate: 'Delivered between Thursday 13 June and Monday 17 June 2024.',
            receipt: 'Pending receipt'
        },
    ],
    Delivered: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Delivered',
            deliveryDate: 'Delivered on: 04 July, 2024',
            receipt: 'Download receipt'
        }
    ],
    Cancelled: [
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled - Payment Unsuccessful',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        },
        {
            imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
            name: 'Butter Chicken Masala',
            ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
            status: 'Cancelled',
            deliveryDate: 'Cancelled on: 04 July, 2024',
            receipt: null
        }
    ]
};

const changeTab = (tab: string) => {
    currentTab.value = tab;
};

const openOrdersModal = () => {
    isOrdersModalOpen.value = true;
    isDropdownOpen.value = false; 
};

const closeOrdersModal = () => {
    isOrdersModalOpen.value = false;
};

const dropdownItems = [
    { icon: 'ant-design:shopping-outlined', text: 'Orders', action: openOrdersModal },
    { icon: 'ant-design:bell-outlined', text: 'Notifications' },
    { icon: 'ant-design:history-outlined', text: 'Recently Viewed' },
    { icon: 'ant-design:gift-outlined', text: 'Rewards' },
    { icon: 'ant-design:heart-outlined', text: 'Favorite Items' },
    { icon: 'ant-design:logout-outlined', text: 'Logout', class: 'border-t-[1px] border-grey-50 text-error pt-[11px] -mb-[11px]' }
];

const getStatusColorClass = (status: any) => {
    switch (status) {
        case 'Shipped':
            return 'bg-yellow-500'; 
        case 'Waiting to be shipped':
            return 'bg-link text-white'; 
        case 'Delivered':
            return 'bg-success text-white'; 
        case 'Cancelled - Payment Unsuccessful':
            return 'bg-black text-white'; 
        case 'Cancelled':
            return 'bg-black text-white'; 
        default:
            return '';
    }
};
</script>

<style scoped>
.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1; 
    background-color: white;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .modal-content {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 700px;

        header {
            border-radius: 8px 8px 0 0;
        }
    }
}
</style>