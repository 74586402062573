import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "border-b-[1px] border-solid border-grey-50 p-[24px] bg-white flex items-center gap-[10px] h-[10vh]" }
const _hoisted_3 = { class: "rounded-[8px] border-[1px] border-solid border-grey-50 w-[32px] h-[32px] relative" }
const _hoisted_4 = { class: "grid lg:grid-cols-2" }
const _hoisted_5 = { class: "h-[90vh] bg-grey-50 overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px] no-scrollbar" }
const _hoisted_6 = { class: "font-medium text-caption text-grey-200" }
const _hoisted_7 = { class: "flex gap-[8px]" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "text-caption text-grey-200" }
const _hoisted_11 = { class: "text-caption text-right grid gap-[8px]" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "flex gap-[16px] text-grey-200" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "h-[90vh] overflow-auto lg:px-[100px] px-[20px] lg:py-[80px] py-[20px]" }
const _hoisted_16 = { class: "grid gap-[8px] my-[42px]" }
const _hoisted_17 = { class: "mb-[42px]" }
const _hoisted_18 = { class: "grid gap-[16px] text-grey-200 text-body-2 mt-[16px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutView',
  setup(__props) {

const getCartBackgroundStyle = (imageUrl: string, height?: number) => ({
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: `${height}px` || '200px', 
    width: '60px',
    border: '1px solid #ccc',
    borderRadius: '8px'
});

const cartMenus = ref([
    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbuzznigeria.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fa-place-of-jollof.jpg&f=1&nofb=1&ipt=68ed73728a29a614ffa05e300091976b2428a541c6ac1c7c398805ce7418d317&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flowcarbafrica.com%2Fwp-content%2Fuploads%2F2020%2F11%2Ffufu-IG-1.jpg&f=1&nofb=1&ipt=a5b41374c5754769b4d87f29d404702e415a9ab24ad5876499b75f296f22321f&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fc7%2Fdd%2Fac%2Fc7ddac4d132b59ec59bbd0a81acbacfb.jpg&f=1&nofb=1&ipt=7a7efaf39c5a032bb59ac3b15efb0a55eccc70f66ebd54a03a36cefcf1c9f118&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },

    {
        imageUrl: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.RRXQgO7BdfOyxvvGR_MBeAHaJQ%26pid%3DApi&f=1&ipt=2cd95c644dc62f4d2ede8ac00cc07c65c82af6f7e7ed599612d0fc92437ef824&ipo=images',
        name: 'Butter Chicken Masala',
        ingredient: 'Sweet and Sour Soup, Veg Manchurian, Chicken Chowmein',
        price: 'N1,800'
    },
]);


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/restaurant" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(Icon), {
              icon: "ant-design:arrow-left-outlined",
              width: "18",
              height: "18",
              class: "text-grey-300 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
            })
          ])
        ]),
        _: 1
      }),
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "font-medium text-sub-heading" }, "Back to cart", -1))
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-medium" }, "Order Information & Review", -1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[3] || (_cache[3] = _createTextVNode("By placing your order, you agree to foodcart’s ")),
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: "text-primary-300 underline"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Privacy")
            ])),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createTextVNode(" and ")),
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: "text-primary-300 underline"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Policy")
            ])),
            _: 1
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cartMenus.value, (menu, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex justify-between cursor-pointer border-b-[1px] border-solid border-grey-50 py-[24px]"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                style: _normalizeStyle(getCartBackgroundStyle(menu.imageUrl, 60))
              }, null, 4),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(menu.name), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(menu.ingredient), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(menu.price), 1),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200" }, "Qty: 1", -1))
            ])
          ]))
        }), 128)),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "mt-[42px] mb-[20px] inline-block" }, "Do you have an existing device?", -1)),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(RadioButton, { value: "Pick-up" }),
          _createVNode(RadioButton, { value: "Delivery" })
        ]),
        _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"text-caption text-grey-200 mt-[22px]\"> Address:<br> 816 Mission St, San Francisco, CA.<br> (0801 2345 6789) </div><div class=\"border-t-[1px] border-solid border-grey-50 py-[24px] mt-[32px] grid gap-[16px]\"><div class=\"flex items-center justify-between\"><div class=\"text-grey-200 text-body-2\">Sub Total</div><div class=\"\">N24,400</div></div><div class=\"flex items-center justify-between\"><div class=\"text-grey-200 text-body-2\">Discount</div><div class=\"\">N0</div></div><div class=\"flex items-center justify-between\"><div class=\"\">Total</div><div class=\"\">N24,400</div></div></div>", 2))
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "font-medium" }, "Payment Details", -1)),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "font-medium text-caption text-grey-200" }, "Complete your purchase by providing your information and make payment", -1)),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(InputField, {
              type: "text",
              label: "Full Name",
              name: "email"
            }),
            _createVNode(InputField, {
              type: "text",
              label: "Email",
              name: "email"
            }),
            _createVNode(InputField, {
              type: "text",
              label: "Phone Number",
              name: "phone"
            })
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "font-medium" }, "Payment Details", -1)),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(RadioButton, { value: "Pay with Bank Transfer (Recommended)" }),
              _createVNode(RadioButton, { value: "Pay with USSD" }),
              _createVNode(RadioButton, { value: "Pay with Direct Bank Charge" })
            ])
          ]),
          _createVNode(ActionButton, {
            theme: "primary",
            label: "Make Payment",
            class: "whitespace-nowrap"
          })
        ])
      ])
    ])
  ]))
}
}

})